//color palette: TODO
$bg-color: #341F32;
$text-color: #F2E1A1;
$primary: #EE660F;
$success: #6A9E39;
$info: #3F4764;
$danger: #D9303E;


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;


//menu in primary color
.sticky-sidebar {
  .nav-link:hover, .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }
}


//have a better looking hamburger menu and icon in sidebar
header {
  .navbar-brand img {
    max-height: 70px;
    margin: -8px 0;
    max-width: 230px !important;
  }
}
.logo-and-nav{
  @media (min-width: 768px) {
    visibility: hidden;
    button {
      display: none;
    }
    .navbar-brand img {
      height: 40px;
    }
  }
}

.logo-and-nav-in-sidebar {
  display: none;
  @media (min-width: 768px) {
    display: block;
    .navbar-brand {
      display: block;
      text-align: center;

      img {
        max-width: 220px;
      }
    }

    .close-btn {
      text-align: right;
      .variable-svg-icon {
        opacity:0.5;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

}

#sidebar-wrapper {
  &.sticky-sidebar {
    top: 0;
    margin-top: 110px;

    @media (min-width: 576px) {
      margin-top: 66px;
    }

    @media (min-width: 768px) {
      margin-top: 0;
      z-index: 2000;
      height: 100vh;
    }

    &.with-token {
      margin-top: 48px;
      @media (min-width: 576px) {
        margin-top: 66px;
      }
      @media (min-width: 768px) {
        margin-top: 0;
      }
    }
  }

}

#main-wrapper {
  margin-top: 120px;

  &.with-token {
    margin-top: 76px;
    @media (min-width: 768px) {
      &.sticky-main {
        margin-top: 50px;
      }
    }
  }

  @media (min-width: 576px) {
    margin-top: 76px;
  }

}







